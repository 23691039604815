import React from 'react'
import { useEffect } from 'react'

function HubSpotForm({ formId, portalId, heading, paragraph }) {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);
        script.addEventListener('load', () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              region: 'na1',
              portalId,
              formId,
              target: `#hubspot-form-${formId}`,
            });
          }
        });
        return () => {
          document.body.removeChild(script);
        };
      }, [formId, portalId]);


    return (
    <section className="hubspot-form">
      <div className="container container--narrow">
        {heading ? (
          <h2 className="hubspot-form__heading text--3 center mt-b-20">{heading}</h2>
        ) : null}
        {paragraph ? (
          <p className="hubspot-form__paragraph text--5 center mt-b-20">{paragraph}</p>
        ) : null}
        <div id={`hubspot-form-${formId}`} className="hubspot-form__inner"></div>
      </div>
    </section>
  )
}

export default HubSpotForm
