import React from 'react'

function Comparison({ data }) {
    // if (!data || data.length !== 2) return null;
  
    return (
        <section className="comparison">
          <div className="container">
            <div className="comparison__inner">
              <div className="comparison__table-wrapper">
                <table className="comparison__table">
                  <thead>
                    <tr>
                      <th className="comparison__header comparison__header--empty"></th>
                      {data.map((column, index) => (
                        <th className="comparison__header" key={index}>
                          <h2 className="comparison__heading text--5">{column.heading}</h2>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data[0].items.map((_, rowIndex) => (
                      <tr className="comparison__row" key={rowIndex}>
                        <td className="comparison__feature">
                          <h3 className="comparison__feature-heading text--7">{data[0].items[rowIndex].heading}</h3>
                        </td>
                        {data.map((column, colIndex) => (
                          <td className="comparison__cell" key={colIndex}>
                            <p className="comparison__paragraph text--6">{column.items[rowIndex].description}</p>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      )
  }
  
  export default Comparison;