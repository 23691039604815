import React from 'react'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import SectionHeader from '../../../components/SectionHeader/SectionHeader'
import Intro from '../../../components/Intro/Intro'
import Comparison from '../../../components/comparison/Comparison';
import HubSpotForm from '../../../components/HubSpotForm/HubSpotForm'
import Outcomes from '../../../components/Outcomes/Outcomes'
import Goals from '../../../components/Goals/Goals'
import TextBlock from '../../../components/TextBlock/TextBlock'

const EmersoftVsBookmanager = () => {

    const comparisonDataGeneral = [
        {
          heading: "Emersoft Ingram Shopify",
          items: [
            {
              heading: "Monthly Platform Fee",
              description: "Starting at $29 (Shopify Basic Plan)"
            },
            {
              heading: "Setup Fee",
              description: "$1,500 (one-time) + $3000 annual billing ($250/mo)"
            },
            {
                heading: "First Year Cost",
                description: "$4500 for setup fee + app access and data"
            },
            {
              heading: "Transaction Fees",
              description: "2.9% + 30¢ per transaction (Shopify Payments)"
            },
            {
                heading: "Customization & Branding Freedom",
                description: "Unlimited customization – Full control over design, layout, fonts, and colors to match your brand vision."
            },
            {
                heading: "Rebrand-Friendly",
                description: "Yes – Adapts to your evolving brand and scales with your business."
            },
            {
                heading: "Automated Order Fulfillment",
                description: "Fully automated with Ingram CDF Lite integration – retains the backend automation Bookmanager users like."
            },
            {
                heading: "Payouts",
                description: "Payments processed instantly through Shopify, no middleman."
            },
            {
                heading: "Reporting & Accounting Integration",
                description: "Extensive reporting features with integration to major accounting software (e.g., QuickBooks, Xero) unlocking unlimited growth opportunities."
            },
            {
                heading: "SEO and Marketing",
                description: "Built-in SEO and marketing tools to drive traffic. You own 100% of your customer data, enabling powerful email marketing, retargeting, and CRM integration."
            },
          ]
        },
        {
          heading: "Bookmanager",
          items: [
            {
                heading: "Monthly Platform Fee",
                description: "Minimum of 1-year commitment"
            },
            {
                heading: "Setup Fee",
                description: "$5000 + $1280 BMData + $430 webstore"
            },
            {
                heading: "First Year Cost",
                description: "$6710"
            },
            {
                heading: "Transaction Fees",
                description: "Additional charges for webstore transactions, customer orders, and third-party vendor integration."
            },
            {
                heading: "Customization & Branding Freedom",
                description: "A closed system with limited customization and fewer integrations."
            },
            {
                heading: "Rebrand-Friendly",
                description: "No – Forces a generic look that doesn’t reflect your unique bookstore personality."
            },
            {
                heading: "Automated Order Fulfillment",
                description: "No automated order fulfillment. Limited to vendor stock visibility and requires more manual management."
            },
            {
                heading: "Payouts",
                description: "Slower payout processing with additional admin fees."
            },
            {
                heading: "Reporting & Accounting Integration",
                description: "A closed system with fewer scalability options."
            },
            {
                heading: "SEO and Marketing",
                description: "Limited marketing capabilities and data access."
            },
          ]
        }
      ];

      const pricingMonthly = [
        {
          id: 111,
          title: '$3000 one-time',
          subtitle: 'Shopify Ingram App Setup Fee',
        },
        {
          id: 222,
          title: '$300 / month',
          subtitle: 'Data and App Access',
        },
      ]
    
      const pricingYearly = [
        {
          id: 111,
          title: '$1500 one-time',
          subtitle: 'Shopify Ingram App Setup Fee (50% off)',
        },
        {
          id: 222,
          title: '$250 / month',
          subtitle: 'Data and App Access ($3000 billed yearly)',
        },
      ]

      const goals = [
        {
          id: 11,
          text: 'Lower Cost, Higher ROI.',
        },
        {
          id: 22,
          text: 'More Sales, No Revenue Cuts.',
        },
        {
          id: 33,
          text: 'No Lock-In: Full Control Over Your Store',
        },
        {
            id: 4,
            text: 'You’re in charge of your store, not Bookmanager!',
        },
        {
            id: 5,
            text: 'Never miss a sale with Emersoft’s automated fulfillment!',
        },
        {
            id: 6,
            text: 'Direct, Instant Payments',
        },
        {
            id: 7,
            text: 'Customer Data Ownership & Advanced Marketing',
        },
        {
            id: 8,
            text: 'Future-Proof Your Bookstore with Shopify’s Ecosystem',
        },
      ]
    return (
        <Layout>
            <SEO
            title="Shopify + Emersoft Ingram App vs. Bookmanager: The Ultimate Bookstore E-commerce Comparison"
            keywords={[
            `Emersoft`,
            `application`,
            `react`,
            `ai`,
            `gpt`,
            `openai`,
            `Emersoft Ingram Shopify Integration`,
            `Bookmanager comparison`,
            `Shopify bookstore solutions`,
            `Independent bookstore e-commerce`,
            `Best online bookstore platform`
            ]}
            description="Discover why integrating Shopify with Emersoft's Ingram App offers superior customization, automation, and ROI for booksellers compared to Bookmanager. Explore our detailed analysis to make an informed decision for your bookstore's online success"
        />
        <SectionHeader>
            Emersoft’s Ingram Integration vs. Bookmanager – The Better Choice for Your Bookstore
        </SectionHeader>
        <Goals title={'Why Emersoft, not Bookmanager'} goalsList={goals} noUnderline={true} />
        <Intro
            paragraph1="Shopify + Emersoft vs. Bookmanager"
        />
        <Comparison data={comparisonDataGeneral} />
        <Intro paragraph1="Pricing" />
        <Outcomes heading="Monthly" outcomes={pricingMonthly} noMarginBottom={true} />
        <Outcomes heading="Annually" outcomes={pricingYearly} />
        <HubSpotForm 
            formId="93f2ee63-651c-439f-bdff-9c02aafe3e74" 
            portalId="46844019" 
            heading="Start Automating Today"
            paragraph="Get started with Ingram's Shopify integration today and see the difference it makes for your business."
        />
        <TextBlock
            heading="Smart Solutions"
            paragraph1="If you’re looking for a cost-effective, scalable, and highly customizable eCommerce solution for your bookstore, Emersoft’s Ingram Integration with Shopify outshines Bookmanager in multiple ways."
            paragraph2="While Bookmanager offers a traditional, closed ecosystem, Emersoft provides full control, better profitability, and greater growth potential with modern automation and Shopify’s powerful features."
        />
        </Layout>
    )
}

export default EmersoftVsBookmanager;